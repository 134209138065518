import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function PodiumsAlphaBravo(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(
    "./models/assets/podiumzone_AlphaBravo.glb"
  );
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="podiumzone_AlphaBravo"
          position={[-30.24, 0.5, -5.37]}
          scale={[1.07, 1.14, 1.07]}
        >
          <mesh
            name="Cube037"
            castShadow
            receiveShadow
            geometry={nodes.Cube037.geometry}
            material={materials["Light_01.009"]}
          />
          <mesh
            name="Cube037_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube037_1.geometry}
            material={materials["Material.009"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./models/assets/podiumzone_AlphaBravo.glb");
