import React, { useRef, useState } from "react";
import * as THREE from "three";
import { SpotLight } from "@react-three/drei";

export function Light(props) {
  const [target2] = useState(() => new THREE.Object3D());
  const [target3] = useState(() => new THREE.Object3D());

  return (
    <>
      <ambientLight intensity={0.85} />
      {/*
      <mesh position={[-3.3, 10, -26.5]}>
        <SpotLight
          castShadow
          target={target2}
          penumbra={0.2}
          radiusTop={0.4}
          radiusBottom={40}
          distance={80}
          angle={0.45}
          attenuation={20}
          anglePower={5}
          intensity={1}
          opacity={2.2}
        />
        <primitive object={target2} position={[0, -0.09, 0]} />
      </mesh>

      <mesh position={[-36.8, 11, 27.3]}>
        <SpotLight
          castShadow
          target={target3}
          penumbra={0.2}
          radiusTop={0.4}
          radiusBottom={40}
          distance={80}
          angle={0.45}
          attenuation={20}
          anglePower={5}
          intensity={0.7}
          opacity={0.1}
        />
        <primitive object={target3} position={[0, -1, 0]} />
      </mesh>
 */}
      {/* 
    
    
    
    <directionalLight
      color={"#cccccc"}
        shadow-mapSize-height={2048}
        shadow-mapSize-width={2048}
        shadow-camera-left={-100}
        shadow-camera-right={100}
        shadow-camera-top={100}
        shadow-camera-bottom={-100}
        castShadow
        position={[50, 100, 50]}
        intensity={0.7}
     />
    */}
    </>
  );
}
