import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Plants(props) {
  const { nodes, materials } = useGLTF(
    "./models/environment/combined/All_Plants.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group
        position={[-26.63, 14.37, 44.46]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.08}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh.geometry}
          material={materials.M_IVY_Plant}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_1.geometry}
          material={materials["lambert1.001"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/environment/combined/All_Plants.glb");
