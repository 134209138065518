import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function ZoneRSE(props) {
  const { nodes, materials } = useGLTF(
    "./models/environment/combined/zone-rse.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={6.86}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube083.geometry}
          material={materials["M_Env_04_Atlas.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube083_1.geometry}
          material={materials.Material}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/environment/combined/zone-rse.glb");
