import React from "react";

import {
  Podiums19Degree,
  PodiumsAlphaBravo,
  PodiumsTegra,
  PodiumsMcLaren,
} from "./PodiumList";

export function Podiums(props) {
  return (
    <group {...props} dispose={null}>
      <Podiums19Degree />
      <PodiumsAlphaBravo />
      <PodiumsTegra />
      <PodiumsMcLaren />
    </group>
  );
}
