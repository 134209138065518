import React, { useEffect, useState } from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

export function Cart({ children, ready }) {
  const { t, i18n } = useTranslation();
  const [showCart, setShowCart] = useState(false);

  const [wishlist, setWishlist] = useState(() => {
    return JSON.parse(localStorage.getItem("wishlist")) || [];
  });
  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  var removeFromCart = function (reference) {
    // Parse the serialized data back into an aray of objects
    // wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];

    // Push the new data (whether it be an object or anything else) onto the array
    let result = wishlist.filter((obj) => obj.reference !== reference);
    setWishlist(result);
    // Alert the array value
    //alert(wishlist);  // Should be something like [Object array]
    // Re-serialize the array back into a string and store it in localStorage

    //localStorage.setItem('wishlist', JSON.stringify(result));
  };
  return (
    <>
      <img
        className={`logo ${isMobile ? "mobile" : "desktop"}`}
        src="images/tumi-logo.png"
      />
      <img
        className="cartButton"
        src="images/cart.png"
        onClick={() => setShowCart(true)}
      />
      <div
        className={`cartList fixed right-0 top-0 h-screen duration-1000 transition-all		${
          isMobile ? "mobile" : "desktop"
        } ${showCart ? "" : "translate-x-full"}`}
      >
        <h3 className="text-center text-darkgrey p-6 font-bold text-base	">
          {t("Whishlist")}
        </h3>
        <button className="close" onClick={() => setShowCart(false)}>
          ×
        </button>
        <ul className="p-2">
          {wishlist.map((element, i) => {
            // Affichage
            return (
              <li className="cartElement" key={i}>
                <img src={element.image} />
                <div className="content">
                  <h4>{element.title[i18n.language]}</h4>
                  <span>{element.category[i18n.language]}</span>
                  <span>{element.price[i18n.language]}</span>
                  <span>
                    {" "}
                    {t("color")} : {element.color[i18n.language]}
                  </span>
                  {t("quantity")} : 2
                  <a onClick={() => removeFromCart(element.reference)}>
                    {t("remove")}
                  </a>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
