import { Text3D, useCursor, useTexture } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import React, { useMemo, useRef, useState, useContext, useEffect} from 'react'
import PopupContext from '../../Context/PopupContext'
import * as THREE from 'three'


export function Text(props) {
  const text = useRef()
  const [near, setNear] = useState(0)
  const [hovered, setHovered] = useState(false)
  const {display, setDisplay} = useContext(PopupContext)


  const position = new THREE.Vector3(props.text.position[0], props.text.position[1], props.text.position[2]);
  const config = useMemo(
    () => ({ size: props.text.size, height: 0.1, curveSegments: 5, position:props.text.position, rotation: props.text.rotation, metalness: 0.15, roughness: 0.1, }),
    []
  )  
  useCursor(hovered)

  var showPopup = function(element){ 
    console.log(element)
    if(element.distance <= 500)
    {
      setDisplay(props.text.reference)
      props.detectClick()
    }
  }
  

  useFrame((state) => {
    if(state.camera.position.distanceTo(text.current.position) < props.text.showDistance)
    {
      const positionShow = new THREE.Vector3(props.text.positionShow[0], props.text.positionShow[1], props.text.positionShow[2]);
        // We enter the zone
        setNear(true)

        text.current.position.lerp(positionShow, 0.01);

    //  console.log(state.camera.position.distanceTo(text.current.position) / props.text.showDistance)
  //    let dist = state.camera.position.distanceTo(text.current.position) / props.text.showDistance
    //  text.current.position.set(text.current.position.x, text.current.position.y, text.current.position.z - dist)
    //  console.log(text.current.position)
    //  text.current.set.position.Z(state.camera.position.distanceTo(text.current.position) / props.text.showDistance)
    }
    else{
      setNear(false)
      
      text.current.position.lerp(position, 0.01);

  //    text.current.translateX(-state.camera.position.distanceTo(text.current.position))
    }
  })
  useEffect(()=>{
      //call your increment function here
      if(near){
       // text.current.position.lerp( props.text.positionShow, 0.1);
      }
      else{
     //   text.current.position.lerp( props.text.position, 0.1);
      }
  },[near]) //and in the array tag the state you want to watch for


  /* TEXTURE */
  const [colorMap] = useTexture([
    './images/textures/Wood.jpg',
  ])
//  const [{ x }] = useSpring({ x: toggle, config: { mass: 5, tension: 1000, friction: 50, precision: 0.0001 } }, [toggle])

 // const pX = x.to([0, 1], [-0.2, 0.4])

  /*  Show hide with spring */
/*
*/

  return ( 
  <Text3D castShadow font={"./font/djv.json"} {...config}    ref={text} 
  onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
  onPointerOut={(e) => setHovered(false)}
  onPointerUp={showPopup}
  >
      {props.text.text}
    <meshStandardMaterial map={colorMap} color={ hovered ? '#fff' : "#ffffff"} metalness={0} roughness={0}  />
</Text3D>
)
}

/*
    <PivotControls rotation={[0, -Math.PI / 2, 0]} anchor={[1, -1, -1]} scale={75} depthTest={false} fixed lineWidth={2} >
  </PivotControls>
 */
