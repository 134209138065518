import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function PodiumT(props) {
  const { nodes, materials } = useGLTF("./models/assets/podiumT.glb");
  return (
    <group {...props} dispose={null}>
      <group
        position={[-20.5, 14.37, 52.5]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={[1.67, 1.67, 2.33]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube033.geometry}
          material={materials["Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube033_1.geometry}
          material={materials.Light_01}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/assets/podiumT.glb");
