import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Env05(props) {
  const { nodes, materials } = useGLTF("./models/environment/cubes/env_05.glb");
  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="env_05"
          castShadow
          receiveShadow
          geometry={nodes.env_05.geometry}
          material={materials["env_05.001"]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={6.86}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/environment/cubes/env_05.glb");
