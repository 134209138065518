
import React, { useRef, useContext, useEffect }  from 'react';
import { useGLTF, PivotControls, Html } from '@react-three/drei'
import PopupContext from '../../Context/PopupContext';
import {  applyProps } from '@react-three/fiber'
import * as THREE from 'three'

export function BagGeneric(props) {
  const { scene, nodes, materials } = useGLTF(props.file)
  const {display, setDisplay} = useContext(PopupContext)
  const ref = useRef()
  var showPopup = function(element){
    if(element.distance <= 5)
    {
      setDisplay(props.name)
      props.detectClick()
    }
  }

if(materials.Valise2)
{
  applyProps(materials.Valise2, {  /*metalness: 0.05, roughness: 0.1,  toneMapped: false,*/ envMapIntensity:0.8 })
}

  return (
      <primitive object={scene}  castShadow receiveShadow onPointerUp={showPopup} />
  )
}
/*position={props.position} scale={props.scale}*/
//<PivotControls ref={ref} rotation={[0, -Math.PI / 2, 0]} anchor={[1, -1, -1]} scale={75} depthTest={false} fixed lineWidth={2} >
//</PivotControls>
