import React from 'react';
import { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'

export function Montgolfiere02(props) {
  const ref = useRef()
  const { nodes, materials } = useGLTF('./models/montgolfiere-solo.glb');
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    ref.current.rotation.set(Math.sin(t) * 0.01, Math.sin(t +2) * 0.015, Math.sin(t + 3) * 0.005)
  })
  return (
    <group  {...props} dispose={null} ref={ref} 
    scale={0.5}  position={[-5.9, 1.3, -7]}>
      <group name="Scene">
        <group name="montgolfiere">
          <mesh
            name="Circle001"
            castShadow
            receiveShadow
            geometry={nodes.Circle001.geometry}
            material={materials.Material}
          />
          <mesh
            name="Circle001_1"
            castShadow
            receiveShadow
            geometry={nodes.Circle001_1.geometry}
            material={materials.lightRouge}
          />
        </group>
      </group>
    </group>
  )
}
