import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function CubeRSE(props) {
  const { nodes, materials } = useGLTF("./models/assets/cubesRSE.glb");
  return (
    <group {...props} dispose={null}>
      <group scale={0.7}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube022.geometry}
          material={materials.Material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube022_1.geometry}
          material={materials.RSE_RMI}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube022_2.geometry}
          material={materials.RSE_BTL}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube022_3.geometry}
          material={materials.RSE_GRN}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/assets/cubesRSE.glb");
