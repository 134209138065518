import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Env02(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("./models/environment/cubes/env_02.glb");
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="env_02001"
          castShadow
          receiveShadow
          geometry={nodes.env_02001.geometry}
          material={materials["env_02.002"]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={6.86}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/environment/cubes/env_02.glb");
