import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Sol(props) {
  const { nodes, materials } = useGLTF("./models/environment/sol.glb");
  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="sol"
          castShadow
          receiveShadow
          geometry={nodes.sol.geometry}
          material={materials["Sol.001"]}
          position={[0.7, 0, 2.1]}
          scale={-63.62}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/environment/sol.glb");
