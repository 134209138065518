
import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";

export function Stair(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("./models/assets/escaliers.glb");


  const bakedStair = useTexture(
    "./models/assets/escaliers/escaliers.webp"
  );

  return (
  <>    
  <group {...props} dispose={null}>
  <group rotation={[-Math.PI / 2, 0, 0]} scale={6.86}>
    <mesh
      castShadow
      receiveShadow
      geometry={nodes.Cube003.geometry}
      material={materials.Escaliers}
      >
        
      <meshBasicMaterial
        map={bakedStair}
        map-flipY={false}
        map-encoding={THREE.sRGBEncoding}
      />
    </mesh>
    <mesh
      castShadow
      receiveShadow
      geometry={nodes.Cube003_1.geometry}
      material={materials.Light_01}
    />
  </group>
</group>

  {/*  <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="escaliers" rotation={[-Math.PI / 2, 0, 0]} scale={6.86}>
          <mesh
            name="Cube003"
            castShadow
            receiveShadow
            geometry={nodes.Cube003.geometry}
          >
          
          <meshStandardMaterial metalness={0.05} roughness={1} color={"#dad8e4"} />
          </mesh>
          <mesh
            name="Cube003_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube003_1.geometry}
            material={materials.Light_01}
          />
        </group>
      </group>
    </group>*/}
  </> 
  );
}

useGLTF.preload("./models/assets/escaliers.glb");
