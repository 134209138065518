import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Podiums19Degree(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "./models/assets/podiumzone_19Degree.glb"
  );
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="podiumzone_Tegra003">
          <mesh
            name="Cube032"
            castShadow
            receiveShadow
            geometry={nodes.Cube032.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            name="Cube032_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube032_1.geometry}
            material={materials["Light_01.006"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./models/assets/podiumzone_19Degree.glb");
