import * as THREE from 'three'
import { useMemo, useRef } from 'react'
import { applyProps, useFrame } from '@react-three/fiber'
import { useGLTF, Billboard } from '@react-three/drei'
import React, { Component }  from 'react';
import { LayerMaterial, Depth } from 'lamina'
import { EffectComposer, Selection, SelectiveBloom, Select, Vignette, Outline  } from '@react-three/postprocessing'



export function LogoTumi(props) {
  const { scene, nodes, materials } = useGLTF('./models/logoTumi.glb') 
  const ref = useRef()
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    ref.current.rotation.set(0, t, 0)
  })

  
  applyProps(nodes.logo.material, {  color: "#000000"})

  return (
    <mesh
      geometry={nodes.logo.geometry}
   //   position={nodes.logo.position} 
      material={nodes.logo.material} 
      castShadow
      scale={0.7}
      position={[0,-0.7,0]}
      ref={ref}
    >
{/* <primitive object={nodes.logo} position={nodes.logo.position}  ref={ref} />  */}
    </mesh>
  )
  }


