import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function PaperBoat(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("./models/objects/boat.glb");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions["boat"].play();
  }, [actions]);
  return (
    <group ref={group} {...props} position={[0, 0.77, 0]} dispose={null}>
      <group name="Scene">
        <group
          name="paperBoat_curves"
          position={[3, -1.27, -19]}
          scale={23.52}
        />
        <mesh
          name="boat"
          castShadow
          receiveShadow
          geometry={nodes.boat.geometry}
          material={materials.Material}
          position={[20.11, -1.27, 5.47]}
          rotation={[0, 0.84, 0]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/objects/boat.glb");
