import React from "react";
import { useGLTF } from "@react-three/drei";

export function PodiumCentral(props) {
  const { nodes, materials } = useGLTF("./models/assets/podiumsSeul.glb");
  return (
    <group {...props} dispose={null}>
      <group scale={0.7}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004.geometry}
          material={materials.Light_01}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004_1.geometry}
          material={materials["Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004_2.geometry}
          material={materials.lightRouge}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/assets/podiumsSeul.glb");
