import React, { useRef, Suspense, useState, useEffect} from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from 'three'
import {  useLoader, useFrame, applyProps } from '@react-three/fiber'
import { MeshLambertMaterial } from "three";


export function UpperCube(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(
    "./models/environment/zoneSustainable.glb"
  );

  const videotest = useRef()


//  applyProps(materials.Buisson, {     side: THREE.DoubleSide, /*metalness: 0.05, roughness: 0.1,  toneMapped: false,*/ envMapIntensity:0.8 })
    
  useFrame((state) => {
    if(videotest.current)
    {
      videotest.current.flipY = false
    }
  })

  const [video] = useState(() => Object.assign(document.createElement('video'), { src: './videos/tumi-valise.mp4', muted: true, crossOrigin: 'Anonymous', loop: true }))
 
  
  useEffect(() => {
    if(video){
      const videotest =  video.play()  
    }
  }, [video])




  return (
    <group {...props} dispose={null}>
    <group position={[-27.51, 14.33, 45.01]} scale={0.49}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-27.02, 14.33, 46.27]}
      rotation={[-Math.PI, 0.09, -Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-25.27, 14.33, 46.9]}
      rotation={[-Math.PI, 0.87, -Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-25.9, 14.33, 45.01]}
      rotation={[-Math.PI, 0.87, -Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-24.71, 14.33, 44.8]}
      rotation={[Math.PI, -0.09, Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-23.17, 14.33, 44.87]}
      rotation={[Math.PI, -Math.PI / 6, Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-23.87, 14.33, 46.27]}
      rotation={[Math.PI, -Math.PI / 6, Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-22.54, 14.33, 47.18]}
      rotation={[Math.PI, -Math.PI / 4, Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-20.51, 14.33, 46.97]}
      rotation={[-Math.PI, 0, -Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-19.95, 14.33, 45.64]}
      rotation={[-Math.PI, 0, -Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-22.05, 14.33, 45.71]}
      rotation={[0, -1.4, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-19.95, 14.33, 45.01]}
      rotation={[0, -1.4, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-22.19, 14.33, 44.94]}
      rotation={[0, -1.4, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-12.67, 14.33, 45.08]}
      rotation={[0, -1.4, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-11.34, 14.33, 45.99]}
      rotation={[0, -1.4, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-11.06, 14.33, 44.94]}
      rotation={[0, 0.17, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-8.47, 14.33, 45.01]}
      rotation={[0, 0.17, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-9.87, 14.33, 46.34]}
      rotation={[-Math.PI, 1.22, -Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-8.4, 14.33, 46.06]}
      rotation={[-Math.PI, 1.22, -Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-10.08, 14.33, 44.8]}
      rotation={[Math.PI, -0.09, Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-8.75, 14.33, 54.6]}
      rotation={[Math.PI, -0.09, Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-8.33, 14.33, 55.58]}
      rotation={[0, -1.22, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-9.87, 14.33, 56.28]}
      rotation={[0, -1.31, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-10.63, 14.33, 57.44]}
      rotation={[0, 1.4, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-8.18, 14.33, 57.44]}
      rotation={[0, 1.4, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-9.89, 14.33, 57.9]}
      rotation={[0, -0.09, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-11.51, 14.33, 59.25]}
      rotation={[0, -0.44, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-9.97, 14.33, 59.53]}
      rotation={[0, 0.09, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-8.36, 14.33, 59.25]}
      rotation={[-Math.PI, 1.31, -Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-10, 14.33, 60.56]}
      rotation={[-Math.PI, 0.44, -Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-8.39, 14.33, 60.91]}
      rotation={[Math.PI, -0.17, Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-8.39, 14.33, 61.82]}
      rotation={[Math.PI, -0.17, Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-9.9, 14.33, 61.06]}
      rotation={[0, -1.4, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-27.75, 14.33, 57.07]}
      rotation={[0, -1.4, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-26.7, 14.33, 58.4]}
      rotation={[0, Math.PI / 9, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-27.47, 14.33, 58.82]}
      rotation={[0, -0.26, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-26.21, 14.33, 59.8]}
      rotation={[0, 0.09, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-27.61, 14.33, 60.22]}
      rotation={[-Math.PI, 1.48, -Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-27.68, 14.33, 61.97]}
      rotation={[-Math.PI, 1.48, -Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-26.7, 14.33, 61.9]}
      rotation={[Math.PI, -1.31, Math.PI]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group
      position={[-26.35, 14.33, 60.22]}
      rotation={[0, -1.57, 0]}
      scale={0.49}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001.geometry}
        material={materials["Herbes_02.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_1.geometry}
        material={materials["Herbes_03.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_2.geometry}
        material={materials["Jaune.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0001_3.geometry}
        material={materials["Herbes_04.001"]}
      />
    </group>
    <group position={[0, -0.02, 0]} scale={0.7}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube013.geometry}
        material={materials.Meterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube013_1.geometry}
        material={materials["RSE_BlackBox.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube013_2.geometry}
        material={materials["RSE_RMI.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube013_3.geometry}
        material={materials["RSE_BTL.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube013_4.geometry}
        material={materials["RSE_GRN.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube013_5.geometry}
        material={materials.Material_Black}
      />
    </group>
    <group
      position={[-10.36, 14.24, 50.37]}
      rotation={[-Math.PI / 2, 0, 0]}
      scale={0.01}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001_M_BottomPlane_0001.geometry}
        material={materials["Material.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001_M_BottomPlane_0001_1.geometry}
        material={materials["Arbres_02.001"]}
      />
    </group>
    <mesh
      castShadow
      receiveShadow
      geometry={nodes.lianes.geometry}
      material={materials["material.001"]}
      position={[-21.64, 20.63, 61.81]}
      rotation={[Math.PI / 2, 0, 0]}
      scale={4.24}
    />
    <group
      position={[0, -0.02, 0]}
      rotation={[0, -1.57, 0]}
      scale={[1.67, 1.67, 2.33]}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube021.geometry}
        material={materials["Material.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube021_1.geometry}
        material={materials["Light_01.001"]}
      />
    </group>
    <mesh
      castShadow
      receiveShadow
      geometry={nodes.Video_RSE.geometry}
      material={materials["TUMI_VALISE 16-9.001"]}
      position={[-18.8, 17.5, 63.49]}
      rotation={[Math.PI / 2, 0, 0]}
      scale={9.4}
    >
      
<meshBasicMaterial toneMapped={false}>
<videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} ref={videotest} />
</meshBasicMaterial>
</mesh>
    <group
      position={[-19.6, 14.21, 44.73]}
      rotation={[Math.PI, -Math.PI / 4, Math.PI]}
      scale={0.01}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0003.geometry}
        material={materials["Herbes_01.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003__0003_1.geometry}
        material={materials["Buisson.001"]}
      />
    </group>
    <group
      position={[-9.97, 14.24, 53.23]}
      rotation={[-Math.PI / 2, 0, 0]}
      scale={0.01}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001_M_BottomPlane_0002.geometry}
        material={materials["Material.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001_M_BottomPlane_0002_1.geometry}
        material={materials["Arbres_04.001"]}
      />
    </group>
    <group
      position={[-27.96, 14.24, 54.71]}
      rotation={[-Math.PI / 2, 0, 0]}
      scale={0.01}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001_M_BottomPlane_0004.geometry}
        material={materials["Material.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001_M_BottomPlane_0004_1.geometry}
        material={materials["Arbres_04.001"]}
      />
    </group>
    <group
      position={[-27.23, 14.24, 50.04]}
      rotation={[-Math.PI / 2, 0, 0]}
      scale={0.01}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001_M_BottomPlane_0003.geometry}
        material={materials["Material.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001_M_BottomPlane_0003_1.geometry}
        material={materials["Arbres_02.001"]}
      />
    </group>
  </group>
      
   
  );
}

useGLTF.preload("./models/environment/zoneSustainable.glb");




