import React, { useState, useEffect } from "react";
import App from "./App";
import Popup from "./Popup";
import { Cart, Title } from "./Ui";
import PopupContext, { DefaultContext } from "./Context/PopupContext";
import CartContext, { CartContentContext } from "./Context/CartContext";
import TitleContext, { TitleContentContext } from "./Context/TitleContext";
import PopupTextContext, {
  DefaultContext as DefaultTextContext,
} from "./Context/PopupContext";
import { create } from "zustand";

export const useStore = create((set) => ({
  position: 0,
  changePosition: (number) => set({ position: number }),
  incrementPosition: (number) =>
    set((state) => ({ position: state.position + number })),
  lerpTime: 0,
  changeLerpTime: (number) => set({ lerpTime: number }),
  animLerp: 0,
  changeAnimLerp: (number) => set({ animLerp: number }),
  incrementAnimLerp: (number) =>
    set((state) => ({ position: state.animLerp + number })),
}));

export default function GlobalApp() {
  const [data, setData] = useState([]);
  const [dataText, setDataText] = useState([]);

  const fetchJson = () => {
    fetch("./tumiproductjson.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const fetchJson2 = () => {
    fetch("./json/listText.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDataText(data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    fetchJson();
    fetchJson2();
  }, []);

  return (
    <>
      {data && (
        <PopupContext.Provider value={DefaultContext()}>
          <CartContext.Provider value={CartContentContext()}>
              <App data={data} dataText={dataText} />
              <Cart />
              <Popup data={data} dataText={dataText} />
          </CartContext.Provider>
        </PopupContext.Provider>
      )}
    </>
  );
}
