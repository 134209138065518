
import React, { Component }  from 'react';
import { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'

export function Montgolfiere(props) {
  const { nodes } = useGLTF('./models/montgolfiere-solo.glb') 
  const ref = useRef()
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    ref.current.rotation.set(Math.sin(t) * 0.05, Math.sin(t) * 0.003, Math.sin(t) * 0.03)
  })
  return (
            <primitive  object={nodes.montgolfiere} position={[15,2,9]} castShadow receiveShadow ref={ref}  />
            
  )
}
