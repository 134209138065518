/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Env03(props) {
  const { nodes, materials } = useGLTF(
    "./models/environment/combined/Env_03_01_4k.glb"
  );
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.env_02001.geometry}
        material={materials.M_Env_03_Atlas}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={6.86}
      />
    </group>
  );
}

useGLTF.preload("./models/environment/combined/Env_03_01_4k.glb");
