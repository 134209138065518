import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Env05(props) {
  const { nodes, materials } = useGLTF(
    "./models/environment/combined/Env_05.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group scale={1}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003.geometry}
          material={materials["Light_01.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003_1.geometry}
          material={materials["Material.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003_2.geometry}
          material={materials["lightRouge.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003_3.geometry}
          material={materials["M_Env_05_Atlas.003"]}
        />
      </group>
    </group>
  );
}
useGLTF.preload("./models/environment/combined/Env_05.glb");
