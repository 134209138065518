import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Env01(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("./models/environment/cubes/env_01.glb");
  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="env_01002"
          castShadow
          receiveShadow
          geometry={nodes.env_01002.geometry}
          material={materials["env_01.003"]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={6.86}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/environment/cubes/env_01.glb");
