/* eslint-disable no-unused-expressions */
import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  memo,
} from "react";
import {
  useGLTF,
  PerspectiveCamera,
  useAnimations,
  Html,
} from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { Map } from "../../Ui/Map";
import { useStore } from "../../GlobalApp";

export const NewCamera = memo((props) => {
  const titleRef = useRef();
  let isMobile = false;

  const { position, changePosition, incrementPosition } = useStore();

  if ("maxTouchPoints" in navigator) {
    isMobile = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    isMobile = navigator.msMaxTouchPoints > 0;
  } else {
    let mQ = window.matchMedia && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      isMobile = !!mQ.matches;
    } else if ("orientation" in window) {
      isMobile = true;
    } else {
      isMobile =
        /Android|Windows Phone|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
    }
  }

  /* SCROLL AMOUNTS >>> GREATER = QUICKER */
  const verticalScrollAmt = 10;
  const horizontalScrollAmt = 10;

  /* GLOBAL VARIABLES FOR UPDATES */
  const mouse = new THREE.Vector2();
  const fullLength = 60000;
  let time = 0;
  let lerpTime = 0;
  let animLerp = 0;
  let ad = 0;
  let ws = 0;
  let adL = 0;
  let wsL = 0;
  let moving = false;
  let mouseDown = false;
  const mouseStart = new THREE.Vector2();
  const mouseDelta = new THREE.Vector2();
  let firstTouch = null;
  let swipingH = false;
  let swipingV = false;
  let clamp = 0;

  /* useRef's AND OBJECTS CREATION */
  const cameraRef = useRef();
  const { scene, nodes, animations } = useGLTF(
    "./models/camera/CamPathBaked.glb"
  );
  const { actions } = useAnimations(animations, scene);

  const duration = actions["clip"]._clip.duration;
  const percentage = duration / fullLength;

  let camPos = nodes.camPos;
  let camLook = nodes.camLook;

  const dummy = useRef();
  const dummyLook = useRef();
  const dummyLerp = new THREE.Group();

  dummyLerp.isCamera = true;
  dummyLerp.position.copy(camPos.position);
  dummyLerp.lookAt(camLook.position);

  /* useEffects */
  useLayoutEffect(() => {
    dummyLook.current.position.setFromSphericalCoords(1.5, -Math.PI / 2, 0);
    dummyLook.current.lookAt(dummy.current.position);
    dummy.current.position.copy(camPos.position);
    dummy.current.lookAt(camLook.position);
  });

  useEffect(() => {
    actions["clip"].play().paused = true;

    /* MOBILE SWIPE EVENTS */
    console.log("rerender");
    if (isMobile) {
      document.addEventListener("touchstart", handleTouchStart, false);
      document.addEventListener("touchmove", handleTouchMove, false);
      document.addEventListener("touchend", handleTouchEnd, false);
    } else {
      /* MOUSE EVENTS */
      document.addEventListener("mousedown", handleMouseDown, false);
      document.addEventListener("mousemove", handleMouseMove, false);
      document.addEventListener("mouseup", handleMouseUp, false);
      document.addEventListener("mousewheel", handleMouseWheel, false);
    }
    if (titleRef.current) {
      titleRef.current.innerHTML = "Accueil";
    }
  });

  function checkTitleTime(scrollTime) {
    if (scrollTime >= 38000) {
      titleRef.current.innerHTML = "Zone RSE";
    } else if (scrollTime >= 30000) {
      titleRef.current.innerHTML = "Tegra";
    } else if (scrollTime >= 21700) {
      titleRef.current.innerHTML = "Alpha Bravo";
    } else if (scrollTime >= 14000) {
      titleRef.current.innerHTML = "19 Degree";
    } else if (scrollTime >= 5000) {
      titleRef.current.innerHTML = "McLaren";
    } else {
      titleRef.current.innerHTML = "Accueil";
    }
  }

  useFrame(() => {
    if (titleRef.current) {
      checkTitleTime(time);
    }
    calculateLerps();
  });

  /* MOUSE EVENTS */
  function handleMouseDown(e) {
    mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
    mouseStart.set(e.clientX, e.clientY);
    moving = false;
    mouseDown = true;
  }

  function handleMouseMove(e) {
    mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

    if (mouseDown) {
      mouseDelta.x = e.clientX - mouseStart.x;
      mouseStart.x = e.clientX;
      mouseDelta.y = e.clientY - mouseStart.y;
      mouseStart.y = e.clientY;

      ad += Math.sin(mouseDelta.x / (5000 / horizontalScrollAmt));
      ws += Math.sin(mouseDelta.y / (5000 / horizontalScrollAmt));

      if (ws >= Math.PI / 2 - 0.01) {
        ws = Math.PI / 2 - 0.01;
      }
      if (ws <= -Math.PI / 2 + 0.01) {
        ws = -Math.PI / 2 + 0.01;
      }

      if (ad > Math.PI) {
        ad = -Math.PI;
      }
      if (ad < -Math.PI) {
        ad = Math.PI;
      }

      dummyLook.current.position.setFromSphericalCoords(
        1.5,
        -Math.PI / 2 + ws,
        ad
      );
      dummyLook.current.lookAt(dummy.current.position);
    }
  }

  function handleMouseUp(e) {
    mouseDown = false;
  }

  function handleMouseWheel(e) {
    moving = true;

    if (!mouseDown) {
      time += e.deltaY * (verticalScrollAmt / 4);
      animLerp = percentage * time;
    }

    if (lerpTime > duration) {
      lerpTime = 0;
      time = 0;
      animLerp = 0;
    }

    if (lerpTime < 0) {
      animLerp = duration;
      time = fullLength;
      lerpTime = duration;
    }

    if (ad >= 0) {
      ad -= 0.1;
      if (ad < 0) {
        ad = 0;
      }
    }
    if (ad <= 0) {
      ad += 0.1;
      if (ad > 0) {
        ad = 0;
      }
    }

    if (ws >= 0) {
      ws -= 0.1;
      if (ws < 0) {
        ws = 0;
      }
    }
    if (ws <= 0) {
      ws += 0.1;
      if (ws > 0) {
        ws = 0;
      }
    }
  }

  /* MOBILE SWIPE EVENTS */
  function handleTouchStart(e) {
    //   e.preventDefault();
    firstTouch = e.touches[0] || e.originalEvent.touches[0];
    mouseStart.set(firstTouch.clientX, firstTouch.clientY);
  }

  function handleTouchMove(e) {
    if (!mouseStart.x || !mouseStart.y) {
      return;
    }

    mouse.x = (e.targetTouches[0].pageX / window.innerWidth) * 2 - 1;
    mouse.y = -(e.targetTouches[0].pageY / window.innerHeight) * 2 + 1;

    var xUp = e.touches[0].clientX;
    var yUp = e.touches[0].clientY;

    var xDiff = mouseStart.x - xUp;
    var yDiff = mouseStart.y - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff) && !swipingV) {
      swipingH = true;
    }
    if (Math.abs(xDiff) < Math.abs(yDiff) && !swipingH) {
      swipingV = true;
    }
    if (swipingH) {
      moving = false;

      mouseDelta.x = e.touches[0].clientX - mouseStart.x;
      mouseStart.x = e.touches[0].clientX;

      ad += Math.sin(mouseDelta.x / (2000 / horizontalScrollAmt));

      if (ad > Math.PI) {
        ad = -Math.PI;
      }
      if (ad < -Math.PI) {
        ad = Math.PI;
      }

      dummyLook.current.position.setFromSphericalCoords(
        1.5,
        -Math.PI / 2 + ws,
        ad
      );
      dummyLook.current.lookAt(dummy.current.position);
    }

    if (swipingV) {
      moving = true;

      mouseDelta.y = e.touches[0].clientY - mouseStart.y;
      mouseStart.y = e.touches[0].clientY;

      if (mouseDelta.y > 0) {
        time += verticalScrollAmt * mouseDelta.y;
      } else if (mouseDelta.y < 0) {
        time -= verticalScrollAmt * -mouseDelta.y;
      }

      if (lerpTime > duration) {
        lerpTime = 0;
        time = 0;
        animLerp = 0;
      }

      if (lerpTime < 0) {
        animLerp = duration;
        time = fullLength;
        lerpTime = duration;
      }

      clamp = THREE.MathUtils.clamp(mouseDelta.y, -5, 5);

      if (ad >= 0) {
        mouseDelta.y > 0 ? (ad -= 0.07 * clamp) : (ad -= 0.07 * -clamp);
        if (ad < 0) {
          ad = 0;
        }
      }
      if (ad <= 0) {
        mouseDelta.y > 0 ? (ad += 0.07 * clamp) : (ad += 0.07 * -clamp);
        if (ad > 0) {
          ad = 0;
        }
      }
    }
  }

  function handleTouchEnd() {
    mouseDown = false;
    swipingH = false;
    swipingV = false;
  }

  // MAIN ANIMATION UPDATE //
  function calculateLerps() {
    animLerp = percentage * time;

    lerpTime = THREE.MathUtils.lerp(lerpTime, animLerp, 0.03);

    adL = THREE.MathUtils.lerp(adL, ad, 0.08);

    wsL = THREE.MathUtils.lerp(wsL, ws, 0.08);

    actions["clip"].time = lerpTime;

    dummyLerp.position.copy(camPos.position);
    dummyLerp.lookAt(camLook.position);
    dummy.current.position.copy(camPos.position);
    dummy.current.quaternion.slerp(dummyLerp.quaternion, 0.2);

    if (moving) {
      dummyLook.current.position.setFromSphericalCoords(
        1.5,
        -Math.PI / 2 + wsL,
        adL
      );
    }

    dummyLook.current.lookAt(dummy.current.position);
    cameraRef.current.quaternion.slerp(dummyLook.current.quaternion, 0.05);
  }

  // const [map, setMap] = useState(true); onClick={() => setMap(true)}
  let map = true;
  function handleCallback(childData) {
    time = 14000;
    animLerp = percentage * time;

    //   setMap(false);
  }

  /* RETURN COMPONENT */
  return (
    <>
      <Html wrapperClass="upperTitle" as="div" prepend>
        <h1 className="title" ref={titleRef}></h1>
      </Html>
      {/*     {map && <Map parentCallback={handleCallback} />}*/}
      <group ref={dummy} isCamera={true}>
        <group ref={dummyLook}></group>
        <PerspectiveCamera ref={cameraRef} makeDefault={true} />
      </group>
    </>
  );
});
