import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function PodiumRse(props) {
  const { nodes, materials } = useGLTF("./models/assets//podium_RSE3.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[-14.54, 15.41, 59.04]} rotation={[0, -0.87, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube074.geometry}
          material={materials["Material.021"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube074_1.geometry}
          material={materials["Light_01.007"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/assets//podium_RSE3.glb");
