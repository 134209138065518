import * as THREE from "three";
import React, { Suspense, useRef, useMemo, useState, useEffect } from "react";
import {
  Canvas,
  extend,
  useThree,
  useLoader,
  useFrame,
} from "@react-three/fiber";
import {
  PointerLockControls,
  Loader,
  KeyboardControls,
  Environment,
  PositionalAudio,
  Stats,
  DeviceOrientationControls,
} from "@react-three/drei";
import {
  Physics,
  CapsuleCollider,
  RigidBody,
  useRapier,
} from "@react-three/rapier";
import {
  Selection,
  SelectiveBloom,
  EffectComposer,
  Outline,
  Bloom,
} from "@react-three/postprocessing";
import { Water } from "three-stdlib";

// import { Cubes } from './Scene/Environment/Cubes'
// import { Ground } from './Scene/Environment/Ground'
// import { Cube } from "./Scene/Environment/Cube"
/*import {
  Light,
  Nuage,
  UpperCube,
  Env01,
  Env02,
  Env03,
  Env04,
  Env05,
  Sol,
  Tunnels,
} from "./Scene/Environment";*/
import { Light, Tunnels } from "./Scene/Environment";

import {
  Env01,
  Env02,
  Env03,
  Env05,
  ZoneRSE,
  Plants,
  CubeRse,
} from "./Scene/Environment/Combined";

import { Screens } from "./Scene/Screens/Screens";

import {
  Stair,
  Couloir,
  Rails2,
  Arches,
  Podiums,
  PodiumT,
  PodiumCentral,
  PodiumRse,
  Vegetations,
  CubeRSE,
  CubeRSENoir,
} from "./Scene/Assets";

/* Objects */

import {
  Avions,
  AvionPapier,
  LogoTumi,
  Montgolfiere,
  Montgolfiere02,
  Train,
  PaperBoat,
} from "./Scene/Objects";

import { NavigationMesh } from "./Scene/NavigationMesh";

import texts from "./Scene/Texts/ListText";
import { Text } from "./Scene/Texts/Text";

import { BagGeneric } from "./Scene/Bags/BagGeneric";
import { Bags } from "./Scene/Bags/Bags";
import { Testbag } from "./Scene/Bags/TestBag";
import { NewBags } from "./Scene/Bags/NewBags";

import { Player } from "./Player";

import { Intro } from "./Ui";

import { ScroolingCamera2 } from "./Scene/Camera/ScroolingCamera2";
import { Cam } from "./Scene/Camera/Cam";
import { NewCamera } from "./Scene/Camera/NewCamera";

extend({ Water });

function Ocean() {
  const ref = useRef();
  const gl = useThree((state) => state.gl);
  const waterNormals = useLoader(THREE.TextureLoader, "./water.jpeg");
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  const geom = useMemo(() => new THREE.PlaneGeometry(1000, 1000), []);
  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      waterColor: 0x80a4fc,
      distortionScale: 3.7,
      fog: false,
      format: gl.encoding,
    }),
    [waterNormals]
  );
  useFrame(
    (state, delta) => (ref.current.material.uniforms.time.value += delta / 25)
  );
  return (
    <water
      ref={ref}
      position={[0, -0.5, 0]}
      args={[geom, config]}
      rotation-x={-Math.PI / 2}
    />
  );
}

function Ready({ setReady }) {
  useEffect(() => {
    setReady(true);
  }, []);

  return null;
}

export default function App(props) {
  const pointer = useRef();

  //const controls = new PointerLockControls( camera, document.body );

  const detectClick = () => {
    if (pointer.current) {
      setTimeout(() => {
        pointer.current.unlock();
      }, 200);
    }
  };

  const listNuagePosition = [
    [150, 100, 80],
    [27, 96, -77],
    [-290, 55, -295],
    [29, 34, 62],
    [134, 73, -107],
    [300, 64, 17],
    [-228, 95, 173],
    [-350, 112, 402],
    [170, 62, 302],
    [-110, 42, -25],
    [130, 62, -302],
    [-150, 112, -142],
  ];

  const listNuageScale = [1, 1, 1.2, 0.4, 0.8, 0.7, 1, 1, 1, 0.8, 0.6, 0.8];

  var renderer = new THREE.WebGLRenderer();
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  const [ready, setReady] = useState(false);

  function test(value) {
    console.log(value);
  }

  return (
    <Intro ready={ready}>
      <KeyboardControls
        map={[
          { name: "forward", keys: ["ArrowUp", "z", "Z"] },
          { name: "backward", keys: ["ArrowDown", "s", "S"] },
          { name: "left", keys: ["ArrowLeft", "q", "Q"] },
          { name: "right", keys: ["ArrowRight", "d", "D"] },
          { name: "jump", keys: ["Space"] },
        ]}
      >
        <Canvas
          shadows
          className="canvas"
          id="capturePointer"
          position={[5, 5, 5]}
        >
          <DeviceOrientationControls />
          <Physics gravity={[0, -30, 0]}>
            <Suspense fallback={<Ready setReady={setReady} />}>
              {/* 
                <Perf />
                <PositionalAudio  position={[0, 2, 0]} autoplay loop url="./afx.mp3" distance={0.3} />
                <ScroolingCamera2 />
                <Cam />
                <Player />
              <NavigationMesh />
              */}
              <Light />
              <NewCamera />
              <Environment
                background={true}
                files="./images/environment/HDRI_v4.hdr"
              />

              <Selection enabled>
                {/* Environment  */}
                <CubeRse />
                <ZoneRSE />
                <Plants />
                <Env01 />
                <Env02 />
                <Env03 />
                <Env05 />
                <Tunnels />

                {/* Old Environment 
                  <Pont/>
                  <Nuages/>
                  <Ground /> 
                  <Cubes />
                <Env04 />
                  
                {Array.from({ length: listNuagePosition.length }, (_, i) => (
                  <Nuage
                    position={listNuagePosition[i]}
                    key={i}
                    scale={listNuageScale[i]}
                  />
                ))}
                <UpperCube />
                <Sol />
                <Vegetations />
                <Stair />
                <Couloir />
                */}

                {/* Assets  */}
                <PodiumCentral />
                <Podiums />
                <PodiumT />
                <PodiumRse />
                <Rails2 />
                <Arches />
                <Train />

                <CubeRSE />

                <CubeRSENoir />

                {/*  Movable Items   */}
                <Screens />
                <Avions />
                <AvionPapier />
                <LogoTumi position={[0, 4, 0]} />
                <Montgolfiere />
                <Montgolfiere02 />
                <PaperBoat />

                {Array.from({ length: texts.length }, (_, i) => (
                  <Text text={texts[i]} key={i} detectClick={detectClick} />
                ))}

                {/*  Bags 
               scale={bag.scale} position={bag.position} 
                <Testbag />*/}
                {props.data.length &&
                  props.data.map((bag) => (
                    <BagGeneric
                      file={"./models/bags/" + bag.item + ".glb"}
                      key={bag.item}
                      detectClick={detectClick}
                      name={bag.item}
                    />
                  ))}
                {/*  Old Movable Items 
                  {props.dataText.length && props.dataText.map((text, index) => ( 
                    <Text text={text}  key={index} detectClick={detectClick} />  
                  ))}          
                  <Bags />
                <NewBags />
                */}
              </Selection>

              <Ocean />
            </Suspense>
          </Physics>
        </Canvas>
      </KeyboardControls>
      <Loader />
    </Intro>
  );
}
