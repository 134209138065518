import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Train(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "./models/objects/bulletTrain.glb"
  );
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions["bulletTrain"].play();
    actions["bulletTrainWagon_01"].play();
    actions["bulletTrainWagon_02"].play();
    actions["bulletTrainWagon_03"].play();
    actions["bulletTrain_04"].play();
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="hBulletTrain" scale={0.7}>
          <group
            name="bulletTrain"
            position={[-54.02, 1.18, 78.63]}
            rotation={[3.14, -1.15, 3.14]}
          >
            <mesh
              name="Object_0003"
              castShadow
              receiveShadow
              geometry={nodes.Object_0003.geometry}
              material={materials.lightRouge}
            />
            <mesh
              name="Object_0003_1"
              castShadow
              receiveShadow
              geometry={nodes.Object_0003_1.geometry}
              material={materials.Material}
            />
            <mesh
              name="Object_0003_2"
              castShadow
              receiveShadow
              geometry={nodes.Object_0003_2.geometry}
              material={materials.Vitres}
            />
            <mesh
              name="Object_0003_3"
              castShadow
              receiveShadow
              geometry={nodes.Object_0003_3.geometry}
              material={materials.Silver}
            />
          </group>
          <group
            name="bulletTrain_04"
            position={[-69.89, 1.15, 54.39]}
            rotation={[0, 0.44, 0]}
          >
            <mesh
              name="Object_0006"
              castShadow
              receiveShadow
              geometry={nodes.Object_0006.geometry}
              material={materials.lightRouge}
            />
            <mesh
              name="Object_0006_1"
              castShadow
              receiveShadow
              geometry={nodes.Object_0006_1.geometry}
              material={materials.Material}
            />
            <mesh
              name="Object_0006_2"
              castShadow
              receiveShadow
              geometry={nodes.Object_0006_2.geometry}
              material={materials.Vitres}
            />
            <mesh
              name="Object_0006_3"
              castShadow
              receiveShadow
              geometry={nodes.Object_0006_3.geometry}
              material={materials.Silver}
            />
          </group>
          <group
            name="bulletTrainWagon_01"
            position={[-58.78, 1.27, 72.58]}
            rotation={[3.14, -0.72, 3.14]}
          >
            <mesh
              name="Object_0"
              castShadow
              receiveShadow
              geometry={nodes.Object_0.geometry}
              material={materials.lightRouge}
            />
            <mesh
              name="Object_0_1"
              castShadow
              receiveShadow
              geometry={nodes.Object_0_1.geometry}
              material={materials.Material}
            />
            <mesh
              name="Object_0_2"
              castShadow
              receiveShadow
              geometry={nodes.Object_0_2.geometry}
              material={materials.Vitres}
            />
          </group>
          <group
            name="bulletTrainWagon_02"
            position={[-62.7, 1.26, 66.96]}
            rotation={[3.14, -0.58, 3.14]}
          >
            <mesh
              name="Object_0004"
              castShadow
              receiveShadow
              geometry={nodes.Object_0004.geometry}
              material={materials.lightRouge}
            />
            <mesh
              name="Object_0004_1"
              castShadow
              receiveShadow
              geometry={nodes.Object_0004_1.geometry}
              material={materials.Material}
            />
            <mesh
              name="Object_0004_2"
              castShadow
              receiveShadow
              geometry={nodes.Object_0004_2.geometry}
              material={materials.Vitres}
            />
          </group>
          <group
            name="bulletTrainWagon_03"
            position={[-66.33, 1.26, 61.14]}
            rotation={[3.14, -0.52, 3.14]}
          >
            <mesh
              name="Object_0005"
              castShadow
              receiveShadow
              geometry={nodes.Object_0005.geometry}
              material={materials.lightRouge}
            />
            <mesh
              name="Object_0005_1"
              castShadow
              receiveShadow
              geometry={nodes.Object_0005_1.geometry}
              material={materials.Material}
            />
            <mesh
              name="Object_0005_2"
              castShadow
              receiveShadow
              geometry={nodes.Object_0005_2.geometry}
              material={materials.Material}
            />
            <mesh
              name="Object_0005_3"
              castShadow
              receiveShadow
              geometry={nodes.Object_0005_3.geometry}
              material={materials.Vitres}
            />
            <mesh
              name="Object_0005_4"
              castShadow
              receiveShadow
              geometry={nodes.Object_0005_4.geometry}
              material={materials.Vitres}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./models/objects/bulletTrain.glb");
