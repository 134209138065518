import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function PodiumsMcLaren(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(
    "./models/assets/podiumzone_MCLaren.glb"
  );
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="podiumzone_MCLaren002" scale={[1.01, 1.11, 1.01]}>
          <mesh
            name="Cube044"
            castShadow
            receiveShadow
            geometry={nodes.Cube044.geometry}
            material={materials["Light_01.011"]}
          />
          <mesh
            name="Cube044_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube044_1.geometry}
            material={materials["Material.012"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./models/assets/podiumzone_MCLaren.glb");
