import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Rails2(props) {
  const { nodes, materials } = useGLTF("./models/assets/rails.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.railsDroits001.geometry}
        material={nodes.railsDroits001.material}
        position={[47.76, -0.04, -9.99]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={2.44}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.railsDroits002.geometry}
        material={nodes.railsDroits002.material}
        position={[37.61, -0.04, -20.72]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={2.44}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.railsDroits003.geometry}
        material={nodes.railsDroits003.material}
        position={[28.8, -0.04, -20.72]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={2.44}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.railsDroits004.geometry}
        material={nodes.railsDroits004.material}
        position={[-1.56, -0.04, -41.6]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={2.44}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.railsDroits005.geometry}
        material={nodes.railsDroits005.material}
        position={[-10.3, -0.04, -41.6]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={2.44}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.railsDroits006.geometry}
        material={nodes.railsDroits006.material}
        position={[-39.15, -0.04, -8.76]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={2.44}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.railsDroits007.geometry}
        material={nodes.railsDroits007.material}
        position={[-39.15, -0.04, -0.01]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={2.44}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.railsDroits008.geometry}
        material={nodes.railsDroits008.material}
        position={[-39.15, -0.04, -17.56]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={2.44}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.railsDroits009.geometry}
        material={nodes.railsDroits009.material}
        position={[47.76, -0.04, -1.19]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={2.44}
      />
    </group>
  );
}

useGLTF.preload("./models/assets/rails.glb");
