import React, { useEffect, useState, memo } from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { Html } from "@react-three/drei";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

export const Map = memo(function (props) {
  //export function Map(props) {
  const [map, setMap] = useState(true);
  //let showMap = true;
  function moveTo(distance) {
    props.parentCallback(distance);
    setMap(false);
  }

  return (
    <>
      {map && (
        <Html wrapperClass="ImageMap" as="div" prepend>
          <img src="./images/map.png" />
          <div className="mclarenZone" onClick={() => moveTo(300)}>
            Click ici
          </div>
        </Html>
      )}
    </>
  );
});
