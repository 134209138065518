/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Env04(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("./models/environment/cubes/env_04.glb");
  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <group name="env_04" rotation={[-Math.PI / 2, 0, 0]} scale={6.86}>
          <mesh
            name="Cube035"
            castShadow
            receiveShadow
            geometry={nodes.Cube035.geometry}
            material={materials["env_04.001"]}
          />
          <mesh
            name="Cube035_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube035_1.geometry}
            material={materials["passageTrain.001"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./models/environment/cubes/env_04.glb");
