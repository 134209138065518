/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import {  applyProps } from '@react-three/fiber'


export function Arches(props) {
  const { nodes, materials } = useGLTF("./models/assets/arches.glb");

  

  const bakeArches = useTexture(
    "./models/assets/arches/arches.webp"
  ); 
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.arches.geometry}
        material={nodes.arches.material}
        scale={0.7}
        >
          
        <meshBasicMaterial
          map={bakeArches}
          map-flipY={false}
          map-encoding={THREE.sRGBEncoding}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("./models/assets/arches.glb");
