import React, { useContext, useState } from "react";
import PopupContext from "./Context/PopupContext";
import { useTranslation } from "react-i18next";

export default function Popup(props) {
  const { display, setDisplay } = useContext(PopupContext);
  const [activeTab, setActiveTab] = useState("features");
  const { t, i18n } = useTranslation();

  const language = i18n.translator.language;

  var showTab = function (element) {
    setActiveTab(element.target.title);
  };

  let selectedData = null;
  let selectedTextData = null;

  if (display) {
    selectedData = props.data.find((obj) => {
      return obj.item === display;
    });
    selectedTextData = props.dataText.find((obj) => {
      return obj.reference === display;
    });
  }

  var stopProp = function (element) {
    if (display) {
      element.stopPropagation();
    }
  };

  var addToCart = function () {
    var wishlist = [];
    // Parse the serialized data back into an aray of objects
    wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    // Push the new data (whether it be an object or anything else) onto the array
    wishlist.push(selectedData);
    // Alert the array value
    //alert(wishlist);  // Should be something like [Object array]
    // Re-serialize the array back into a string and store it in localStorage
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 h-full bg-grey	 bg-opacity-50  w-full overflow-y-auto overflow-x-hidden outline-none"
        style={{ display: display ? "inline" : "none" }}
      >
        <div className="popup py-5 px-2  sm:p-10" onClick={stopProp}>
          <div
            onClick={() => setDisplay(null)}
            id="capturePointer"
            className="closeButton"
          >
            ×
          </div>
          {selectedTextData && (
            <>
              <div className="storypopup">
                <h2 className="text-xl sm:text-3xl">
                  {selectedTextData.title}
                </h2>
                <div
                  className="p-10"
                  dangerouslySetInnerHTML={{ __html: selectedTextData.content }}
                />
              </div>
            </>
          )}
          {selectedData && (
            <>
              <div class="inner-wrapper">
                <div className="product-col-2 product-info_top">
                  <h1>{selectedData.title.fr}</h1>
                  <span className="collection">{selectedData.collection}</span>
                  <p className="price">{selectedData.price[language]}</p>
                  <div className="color">
                    <span>{t("Color")} :</span> {selectedData.color.fr}
                  </div>
                </div>
                <div className="product-col-1 product-image-container">
                  <div className="product-main-image-container">
                    <div className="product-main-image">
                      <img
                        src={selectedData.image}
                        alt={selectedData.title.fr}
                      />
                    </div>
                  </div>
                </div>
                <div className="product-col-2 product-info_product-details">
                  <ul className="tab-list">
                    <li
                      title="features"
                      onClick={showTab}
                      className={activeTab === "features" ? "active" : null}
                    >
                      {t("Features")}{" "}
                    </li>
                    <li>|</li>
                    <li
                      title="about"
                      onClick={showTab}
                      className={activeTab === "about" ? "active" : null}
                    >
                      {t("About")}{" "}
                    </li>
                  </ul>

                  {activeTab === "features" && (
                    <div className="product-specifications">
                      <p className="sku-label text-darkgrey">
                        <strong class="text-grey">{t("Style")} :</strong>{" "}
                        139681-1776
                      </p>
                      <ul className="product-specifications__list">
                        <li className="customDimension-Outside">
                          <p className="title">{t("Dimensions")} </p>
                          <p className="value">{selectedData.dimension.fr}</p>
                        </li>
                        <li className="customProductWeight">
                          <p className="title">{t("Weight")} </p>
                          <p className="value">{selectedData.weight.fr}</p>
                        </li>
                        <li className="customMaterial">
                          <p className="title">{t("Material")} </p>
                          <p className="value">{selectedData.material.fr}</p>
                        </li>
                      </ul>
                    </div>
                  )}
                  {activeTab === "about" && (
                    <div className="product-specifications">
                      About this article
                    </div>
                  )}
                  <button
                    onClick={addToCart}
                    className="redButton mt-5	w-full addtoCart"
                  >
                    {t("Cart")}{" "}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
