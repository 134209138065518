import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Avions(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("./models/avionAnimation.glb");
  const { actions } = useAnimations(animations, group);
  useEffect(() => {

    actions["avion.001"].timeScale = 0.2
    let action1 = actions["avion.001"].play();
    actions["avion.000"].timeScale = 0.3
     actions["avion.000"].play();
}, [actions]);

return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Avion_01" position={[0, 180, 0]} scale={33.6} />
        <group name="Avion_02" position={[14, 184, -19]} scale={33.6} />
        <mesh
          name="avion000"
          castShadow
          receiveShadow
          geometry={nodes.avion000.geometry}
          material={materials.Material}
          position={[-55.79, 179.95, 36.4]}
          rotation={[0, 0.88, 0]}
        />
        <mesh
          name="avion001"
          castShadow
          receiveShadow
          geometry={nodes.avion001.geometry}
          material={materials.Material}
          position={[13.31, 183.95, 36.86]}
          rotation={[-Math.PI, 1.54, -Math.PI]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/avionAnimation.glb");
