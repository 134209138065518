/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function CubeRse(props) {
  const { nodes, materials } = useGLTF(
    "./models/environment/combined/cubes_rse.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group scale={0.7}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.puzzle_01.geometry}
          material={nodes.puzzle_01.material}
          position={[0, 2, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.puzzle_05.geometry}
          material={nodes.puzzle_05.material}
          position={[0, 2, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.puzzle_08.geometry}
          material={nodes.puzzle_08.material}
          position={[0, 2, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
        />
        <group position={[0, 1.8, 0]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.puzzle_02.geometry}
            material={nodes.puzzle_02.material}
            position={[0, 2, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={9.8}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.puzzle_04.geometry}
            material={nodes.puzzle_04.material}
            position={[0, 2, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={9.8}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.puzzle_09.geometry}
            material={nodes.puzzle_09.material}
            position={[0, 2, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={9.8}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.puzzle_03.geometry}
          material={nodes.puzzle_03.material}
          position={[0, 2, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.puzzle_06.geometry}
          material={nodes.puzzle_06.material}
          position={[0, 2, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.puzzle_10.geometry}
          material={nodes.puzzle_10.material}
          position={[0, 2, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
        />
        <group position={[0, 1.6, 0]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.puzzle_07.geometry}
            material={nodes.puzzle_07.material}
            position={[0, 2, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={9.8}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./models/environment/combined/cubes_rse.glb");
