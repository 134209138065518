import React, { useRef, useState } from "react";
import { useGLTF, Instances, Instance, PivotControls } from "@react-three/drei";
import {  applyProps, useFrame } from '@react-three/fiber'

export function Nuage(props) {
  const { nodes, materials } = useGLTF("./models/Nuages_Seul.glb");
  const ref = useRef()
  const ref2 = useRef()
  
  const [moving, setMoving] = useState(getRandomArbitrary(0.001,0.01))
  const [rotating, setRotating] = useState(getRandomArbitrary(-10,10))
  const [rotating2, setRotating2] = useState(getRandomArbitrary(-5,5))
  applyProps(materials.Material, { metalness: 0.05, roughness: 0.1, color: "#c8c7c6"})


  useFrame((state) => {
    const t = state.clock.getElapsedTime()
      ref.current.translateZ(Math.sin((t*0.0001)/ rotating) * rotating2)
      ref.current.translateY(Math.sin(t)*moving)
      ref2.current.rotation.set(-Math.PI / 2+Math.sin(t) * moving, Math.sin(t) * moving,Math.sin(t) * moving)
  })
  return (
  <Instances
    geometry={nodes.cubes001.geometry}
    material={materials.Material}
    ref={ref}
    >
    <group {...props} dispose={null}>

       <Instance  ref={ref2}
    rotation={[-Math.PI / 2, 0, 0]}
    scale={9.8} />
    </group>
    </Instances>
  );
}

export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

useGLTF.preload("./models/Nuages_Seul.glb");
