import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture, Instances, Instance } from "@react-three/drei";
import { applyProps } from "@react-three/fiber";

export function Couloir(props) {
  const { scene, nodes, materials } = useGLTF("./models/assets/couloirs.glb");
  applyProps(materials.Material, {
    metalness: 0.75,
    roughness: 0.2,
    color: "#fff",
    envMapIntensity: 1,
    attenuationDistance: 0,
  });

  const bakedCouloir = useTexture("./models/assets/couloirs/couloirs.webp");

  return (
    <Instances geometry={nodes.couloir.geometry} material={materials.Material}>
      <group {...props} dispose={null}>
        {[...Array(83)].map((props, i) => (
          <Instance
            key={i}
            scale={1}
            position={[0, -0.01 * i, i * 0.14]}
          ></Instance>
        ))}
      </group>
    </Instances>
  );
}

useGLTF.preload("./models/assets/couloirs.glb");
