import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function NewBags(props) {
  const { nodes, materials } = useGLTF("./models/bags/placement_valises.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[-3.66, 1.51, -25.12]} scale={0.1}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.accordeon_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.attaches_poignees_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.bouton_poignee_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.charnieres_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.fermetures_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.metl_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.poignee_1_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.poignee_2_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.poignee_3_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.roues_code_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.roues_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.supports_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.supports_roues_low001.geometry}
          material={materials.Valise}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.valise_low002.geometry}
          material={materials.Valise2}
        />
      </group>
      <group
        position={[30.59, 0.96, -8.92]}
        rotation={[0, -1.23, 0]}
        scale={0.04}
      >
        <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Sac_a_dos_velocity001.geometry}
            material={materials.M_Sac_a_dos_velocity}
          />
        </group>
      </group>
      <group
        position={[28.98, 0.55, -10.81]}
        rotation={[0, -1.31, 0]}
        scale={0.03}
      >
        <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SacDuffel001.geometry}
            material={materials.M_SacDuffel}
          />
        </group>
      </group>
      <group
        position={[31.09, 0.27, -10.85]}
        rotation={[Math.PI, -1.04, Math.PI]}
        scale={0.03}
      >
        <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.BagageAMainAero001.geometry}
            material={materials.M_BagageAMainAero}
          />
        </group>
      </group>
      <group position={[-29.09, 0.59, -4.75]} scale={0.01}>
        <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.BravoNavigation002.geometry}
            material={materials["ZenUV_Generic_Material.001"]}
          />
        </group>
      </group>
      <group position={[-31.09, 0.5, -4.8]} scale={0.03}>
        <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SacCeinture001.geometry}
            material={materials.M_SacCeinture}
          />
        </group>
      </group>
      <group position={[-30.13, 0.92, -7.04]} scale={0.04}>
        <group rotation={[-Math.PI / 2, 0, 0]} scale={[89.42, 100, 100]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001.geometry}
            material={materials.Cube_005}
          />
        </group>
      </group>
      <group
        position={[-30.29, 1.7, 26.72]}
        rotation={[-Math.PI, 0.96, -Math.PI]}
        scale={0.03}
      >
        <group scale={4.31}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.valise_low003.geometry}
            material={materials.M_Carbone}
          />
        </group>
      </group>
      <group
        position={[-31.15, 0.41, 24.78]}
        rotation={[-Math.PI, 0.95, -Math.PI]}
        scale={0.03}
      >
        <group
          position={[0.14, 29.2, 12.31]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.TegraWithPocket001.geometry}
            material={materials["M_Carbone.001"]}
          />
        </group>
      </group>
      <group position={[9.92, 0.23, 15.18]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh010.geometry}
          material={materials["4A_Fabric_Even_mqm"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh010_1.geometry}
          material={materials["19DegrePC"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh010_2.geometry}
          material={materials.Roulettes}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/bags/placement_valises.glb");