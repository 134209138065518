import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function PodiumsTegra(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("./models/assets/podiumzone_Tegra.glb");
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="podiumzone_Tegra001">
          <mesh
            name="Cube002"
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            name="Cube002_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube002_1.geometry}
            material={materials["Light_01.006"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./models/assets/podiumzone_Tegra.glb");
