import React, { useRef, useState } from "react";
import { useProgress } from "@react-three/drei";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

function Loader() {
  const { progress } = useProgress();
  return (
    <div className="centerDiv">
      <img className="logointroback" src="./images/intro/tumi-logo_black.png" />
      <div
        className="overflowlogofront"
        style={{ width: `calc(338px * 0.01 * ${progress.toFixed()} )` }}
      >
        <img
          className="logointrofront"
          src="./images/intro/tumi-logo_white.png"
        />
      </div>
    </div>
  );
}

function Loaded() {
  const [isHover, setIsHover] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="centerDiv">
        <img
          className="logointrofront"
          src="./images/intro/tumi-logo_white.png"
        />
      </div>

      <div className="vertical-center">
        <button
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className={`enterbutton fade-in ${isHover ? "isHover" : "notHover"}`}
        >
          {t("enterHere")}
        </button>
      </div>
    </>
  );
}

export function Intro({ children, ready }) {
  const [clicked, setClicked] = useState(false);
  const [lastclicked, setLastClicked] = useState(false);
  const [playvideo, setPlayVideo] = useState(false);
  const video = useRef();
  const [selectedButton, setSelectedButton] = useState("EN");

  const { t, i18n } = useTranslation();

  const language = i18n.translator.language;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleMenuOne = () => {
    setSelectedButton("EN");
    changeLanguage("en");
  };

  const handleMenuTwo = () => {
    setSelectedButton("FR");
    changeLanguage("fr");
  };

  const handleMenuThree = () => {
    setSelectedButton("ES");
    changeLanguage("es");
  };

  {
    /* onClick={() => ready && setClicked(true)} */
  }

  const handlePlayVideo = () => {
    setPlayVideo(true);
    video.current.play();
    setClicked(true);
  };

  const handleEndVideo = () => {
    setPlayVideo(false);
  };

  return (
    <>
      {children}

      {/* Splashscreen */}
      <div
        className={`fullscreen bg ${ready ? "ready" : "notready"} ${
          clicked && "clicked"
        } ${isMobile ? "mobile" : "desktop"} `}
      >
        <div className="greybg">
          <a className="continue-link" href="#" onClick={handlePlayVideo}>
            {!ready ? <Loader /> : <Loaded />}
          </a>
          <a
            className="mentionlink"
            href="https://fr.tumi.com/terms.html"
            target="_blank"
          >
            {t("legalnotice")}
          </a>
          <p className={` textBottom ${ready ? "fade-in" : "hide"}`}>
            {t("introText")}
          </p>
          <Dropdown
            trigger={<button className="mainButton">{selectedButton}</button>}
            menu={[
              <button onClick={handleMenuOne}>EN</button>,
              <button onClick={handleMenuTwo}>FR</button>,
              <button onClick={handleMenuThree}>ES</button>,
            ]}
          />
        </div>
      </div>
      {/* Video */}
      <video
        onEnded={handleEndVideo}
        className={playvideo ? "video" : "video hide"}
        id="full-screenVideo"
        ref={video}
      >
        <source src="/videos/video_intro.mp4" type="video/mp4" />
      </video>

      {/* Explain screen */}

      {!lastclicked && (
        <div
          className={`fullscreen prescreen ${lastclicked && "hide"}`}
          onClick={setLastClicked}
          style={{ zIndex: `99` }}
        >
          <div className="contentflex desktop">
            <div className="item">
              <div className="blockImage">
                <img src="./images/intro/onboarding_pointeur.png" />
              </div>
              <p>{t("clickDrag")}</p>
            </div>
            <img className="barre" src="./images/intro/onboarding_barre.png" />
            <div className="item">
              <div className="blockImage">
                <img src="./images/intro/onboarding_souris.png" />
              </div>
              <p>{t("scrollToExplore")}</p>
              <br />
            </div>
          </div>
          <div className="contentflex mobile-only mobile">
            <div className="item">
              <div className="blockImage">
                <img src="./images/intro/onboarding_mobile_swipe.png" />
              </div>
              <p>{t("swipeUp")}</p>
            </div>
            <img
              src="./images/intro/onboarding_barre.png"
              style={{ rotate: "90deg" }}
            />
            <div className="item">
              <div className="blockImage">
                <img src="./images/intro/onboarding_mobile_rotate.png" />
              </div>
              <p>{t("swipeLeft")}</p>
              <br />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export function Dropdown({ trigger, menu }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="dropdown">
      <div className={` ${!open ? "fade-in" : "hide"}`}>
        {React.cloneElement(trigger, {
          onMouseEnter: handleOpen,
        })}
      </div>
      <ul
        className={` menu ${open ? "fade-in" : "hide"}`}
        onMouseLeave={() => setOpen(false)}
      >
        {menu.map((menuItem, index) => (
          <li key={index} className="menu-item">
            {React.cloneElement(menuItem, {
              onClick: () => {
                menuItem.props.onClick();
                setOpen(false);
              },
            })}
          </li>
        ))}
      </ul>
    </div>
  );
}
