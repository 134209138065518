import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Vegetations(props) {
  const { nodes, materials } = useGLTF("./models/assets/vegetations.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.lianes.geometry}
        material={materials.material}
        position={[-21.64, 20.63, 61.81]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={4.24}
      />
    </group>
  );
}

useGLTF.preload("./models/assets/vegetations.glb");
